<template>
  <div class="col-4 pa-0">
    <div class="stock">
      <div class="header-content">
        <h1>Transaction Summary</h1>
        <span class="material-icons"> more_horiz </span>
      </div>

      <div class="chart-section">
        <div class="bill">
          <div class="display-bill">
            <h1>Transaction Summary</h1>
            <p>{{ fromDate }}</p>
          </div>

          <div class="bill">
            <!-- <h1>$1839.00</h1> -->
          </div>
        </div>

        <div>
          <div id="chart">
            <apexchart
              type="area"
              height="150"
              :options="data.chartOptions"
              :series="data.series"
            ></apexchart>
          </div>
        </div>
      </div>

      <div class="profit-statement">
        <div
          class="profit-loss"
          v-for="(item, index) in transactions"
          :key="index"
        >
          <div class="display-bill">
            <h1>{{ item.name }}</h1>
            <p>{{ item.transaction_date }}</p>
          </div>

          <div class="bill">
            <h1><strong>{{ item.amount.currency() }}</strong></h1>
            <span class="material-icons"> expand_less </span>
          </div>
        </div>
      </div>
      <div v-if="transactions.length > 0" class="stock-footer mt-5">
        <router-link to="/receipt-report">
          <h1>View All</h1>
        </router-link>
        <span class="material-icons side-bar"> arrow_forward_ios </span>
      </div>
    </div>
  </div>
</template>
<style>
.chart {
  min-height: 100px !important;
}

.dashboard .stock .chart-section #chart {
  margin: 0px auto !important;
  width: 100% !important;
}

.SvgjsSvg4451 {
  height: 100px;
}

.apexcharts-series #SvgjsPath1378 {
  fill: #d0ccde !important;
}

.apexcharts-series #SvgjsPath1379 {
  stroke: #4527a0 !important;
}
</style>
<script>
const NepaliDate = require('nepali-date');
const nd = new NepaliDate();
export default {
  data() {
    return {
      data: {
        series: [
          {
            name: 'Transaction Summary',
            data: [],
          },
        ],
        chartOptions: {
          fill: {
            colors: ['#EDE7F6'],
            type: 'gradient',
            opacity: 1,
            gradient: {
              type: 'vertical',
              shadeIntensity: 1,
              gradientToColors: ['#B39DDB'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 90, 100],
              colorStops: [],
            },
          },

          chart: {
            toolbar: {
              show: false,
            },
            height: 100,
            type: 'area',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            colors: ['#673AB7'],
            width: 1.4,
          },
          yaxis: {
            opposite: true,
            show: false,
            labels: {
              maxWidth: 'auto',
              style: {
                colors: ['#BEBFCB'],
                fontSize: '10px',
              },
            },
          },
          xaxis: {
            opposite: true,
            show: true,
            type: 'date',
            categories: [],

            labels: {
              show: true,
            },
          },
          legend: {
            show: false,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy',
            },
          },
        },
      },
      fromDate: nd.format('YYYY-MM-DD'),
      toDate: nd.format('YYYY-MM-DD'),
      transactions: [],
      transactionSummary: [],
    };
  },
  mounted() {
    this.getTransactionSummary();
    this.getTransaction();
  },
  methods: {
    getTransactionSummary() {
      this.$rest
        .get(`/api/report/transaction-summary?rowsPerPage=5&descending=true`)
        .then(({ data }) => {
          let $this = this;
          let chartData = [];
          let sod_date = [];
          Object.keys(data.data).forEach(function (key) {
            let amount_collection_value = Object.values(
              data.data[key].amount_collection
            );
            const sum = amount_collection_value.reduce((accumulator, value) => {
              return accumulator + value;
            }, 0);

            chartData.push(sum);
            sod_date.push(data.data[key].sod_date);
          });
          $this.data.chartOptions.xaxis.categories = sod_date;
          // $this.data.chartOptions.xaxis.categories = [ "2022-05-26T00:00:00.000Z",
          //     "2022-05-27T00:00:00.000Z"];
          $this.data.series.push({
            data: chartData,
          });
        });
    },
    getTransaction() {
      this.$rest
        .get(
          `/api/report/transaction-list?rowsPerPage=5&descending=true&via=all&transactionReport=true&transactionListType=all&accountant=`
        )
        .then(({ data }) => {
          this.transactions = data.data;
        });
    },
  },
};
</script>
